import { makeStyles } from '@material-ui/core'
import React from 'react'
import DefaultSlider from 'react-slick'
import ArrowBack from '@material-ui/icons/ArrowBackIos'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { useMobile } from '../utils/useMobile'
import { cx } from '../utils/cx'
import '../css/slick.min.css'
import '../css/slick-theme.min.css'

const useStyles = makeStyles(() => ({
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    margin: '14px',
    cursor: 'pointer',
    zIndex: 1,
    left: 0
  },
  mobileArrow: {
    fontSize: '20px'
  },
  rightArrow: {
    right: 0,
    left: 'auto',
    transform: 'rotate(180deg) translate(0, 50%)'
  },
  slider: {
    height: '100% !important',
    '& .slick-list, & .slick-track': {
      height: '100% !important',
      display: 'flex',
      alignItems: 'center'
    },
    '& .slick-slide': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& img': {
        height: 'auto'
      }
    }
  }
}))
export function Slider ({
  interactable = false,
  arrowClassname,
  className,
  ...props
}) {
  const classes = useStyles()
  const mobile = useMobile()

  return (
    <DefaultSlider
      draggable={interactable}
      prevArrow={<Arrow mobile={mobile} arrowClassname={arrowClassname} prev />}
      nextArrow={<Arrow mobile={mobile} arrowClassname={arrowClassname} />}
      arrows={interactable}
      autoplay={!interactable}
      pauseOnHover={!interactable}
      className={cx(classes.slider, className)}
      {...props}
    />
  )
}

function Arrow (props) {
  const { prev, mobile, onClick, arrowClassname } = props
  const classes = useStyles()
  const Component = mobile ? KeyboardArrowLeftIcon : ArrowBack

  return (
    <Component
      onClick={onClick}
      className={cx(
        classes.arrow,
        !prev && classes.rightArrow,
        mobile && classes.mobileArrow,
        arrowClassname
      )}
    >
      {prev ? '<' : '>'}
    </Component>
  )
}

export default Slider
